import useEditor         from '@/ol/editor/useEditor';
import React             from 'react';
import PlaceSidebar      from '@/ol/editor/sidebar/PlaceSidebar';
import ChooseTypeSidebar from '@/ol/editor/sidebar/ChooseTypeSidebar';

export default function Sidebar() {
	const { editor } = useEditor();

	const [feature, setFeature] = React.useState(editor.selectedFeature);

	React.useEffect(() => editor.selectedFeature && setFeature(editor.selectedFeature), [editor.selectedFeature]);

	if (!feature) return null;

	const properties = feature.getProperties() as FeatureProperties;
	const entity = properties.entity!;

	if (entity) return <PlaceSidebar entity={entity} />;

	return <ChooseTypeSidebar />;
}