import EditorHeader   from '@/ol/editor/EditorHeader';
import EditorProvider from '@/ol/editor/EditorProvider';
import EditorSidebar  from '@/ol/editor/EditorSidebar';
import { observer }   from 'mobx-react-lite';
import useEditor      from '@/ol/editor/useEditor';
import Sidebar        from '@/ol/editor/sidebar';
import View           from '@widesk/components/View';
import EditionBar     from '@/ol/editor/EditionBar';

export default function Editor() {
	return (
		<EditorProvider>
			<EditorHeader />

			<ObservedEditor />
		</EditorProvider>
	);
}

const ObservedEditor = observer(() => {
	const { editor } = useEditor();

	return (
		<EditorSidebar
			opened={!!editor.selectedFeature}
			sidebarContent={() => <Sidebar />}
		>
			<View widthF heightF rel>
				<div id="map" className="map" style={{ flex: 1 }}></div>

				<EditionBar />
			</View>
		</EditorSidebar>
	);
});