import { FeatureLike } from 'ol/Feature';
import { getSize }     from 'ol/extent';

export default (feature: FeatureLike, resolution: number) => {
	const extent = feature.getGeometry()!.getExtent();

	// Récupération de la largeur du polygon en pixel
	const extentPixelWidth = getSize(extent)[0]! / resolution;

	return extentPixelWidth > 15;
};