import { Fill }                    from 'ol/style.js';
import { Icon }                    from 'ol/style.js';
import { Stroke }                  from 'ol/style.js';
import { Style }                   from 'ol/style.js';
import { Text }                    from 'ol/style.js';
import { Point }                   from 'ol/geom';
import { FeatureLike }             from 'ol/Feature';
import { getCenter }               from 'ol/extent';
import checkFeatureForImageDisplay from '@/ol/tools/checkFeatureForImageDisplay';

const colored = new URLSearchParams(window.location.search).has('c');

const baseStyle = new Style({
	stroke: new Stroke({
		color: colored ? 'blue' : 'transparent',
		// lineDash: [4],
		width: 1,
	}),
	fill: new Fill({
		color: 'rgba(0, 0, 0, 0)',
	}),
});

const icons: Record<number, Icon> = {};

export default (feature: FeatureLike, resolution: number): Style[] => {
	const styles = [baseStyle];

	if (feature.get('selected')) {
		styles.push(
			new Style({
				stroke: new Stroke({
					color: 'red',
					width: 4,
				}),
			})
		);
	}

	const properties = feature.getProperties() as FeatureProperties;

	const entity = properties.entity;

	if (properties.imageEnabled && entity?.imageRotation && entity?.image) {
		const iconStyle = getIconStyle(feature, resolution);
		if (iconStyle) {
			styles.push(iconStyle);
		}
	}

	styles.push(
		new Style({
			text: new Text({
				font: '10px Calibri,sans-serif',
				text: entity?.location,
				offsetX: 0, // ajuster si nécessaire
				offsetY: 0, // ajuster si nécessaire
				textAlign: 'center',
				fill: new Fill({
					color: '#000000',  // Couleur du texte (noir ici)
				}),
				backgroundFill: new Fill({
					color: 'rgba(255,255,255,0.2)',  // Fond blanc sous le texte
				}),
				padding: [2, 2, 0, 2],  // Padding [haut, droite, bas, gauche]
			}),
		}),
	);

	return styles;
};

const getIconStyle = (feature: FeatureLike, resolution: number) => {
	if (!checkFeatureForImageDisplay(feature, resolution)) return null;

	const extent = feature.getGeometry()!.getExtent();

	const src = feature.getProperties().entity.image;

	const id = feature.getProperties().entity.id;

	if (!icons[id]) {
		icons[id] = new Icon({ src, anchor: [0.5, 0.5], scale: 0 });
	}

	const icon = icons[id]!;

	// Récupérer l'objet image une fois chargé
	const img = icon.getImage(1) as HTMLImageElement;

	const styleImage = new Style({
		image: icon,
		geometry: () => new Point(getCenter(extent)),
	});

	if (img.complete) {
		const width = img.width;
		const height = img.height;

		const extentRatio = (extent[3]! - extent[1]!) / (extent[2]! - extent[0]!);
		const imageRatio = height / width;

		const extentWidth = extent[2]! - extent[0]!;
		const extentHeight = extent[3]! - extent[1]!;

		const imageScale = extentRatio > imageRatio ? extentWidth / width : extentHeight / height;

		const size = 0.4;

		icon.setScale((imageScale * size) / resolution);
	}

	return styleImage;
};