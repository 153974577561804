import GeoImageExt                    from 'ol-ext/layer/GeoImage';
import type ImageSource               from 'ol/source/Image';
import type { Options as OptionsExt } from 'ol/layer/BaseImage';
import ViewHint                       from 'ol/ViewHint';
import { Feature }                    from 'ol';
import Geometry                       from 'ol/geom/Geometry';
import GeoImageSource                 from 'ol-ext/source/GeoImage';
import { Extent }                     from 'ol/extent';
import { getIntersection }            from 'ol/extent';
import { isEmpty }                    from 'ol/extent';
import CanvasImageLayerRenderer       from 'ol/renderer/canvas/ImageLayer';
import { fromUserExtent }             from 'ol/proj';
import ImageState                     from 'ol/ImageState';
import updateScaleFromExtent          from '@/ol/updateScaleFromExtent';

type CustomOptions = {
	updateWhileInteracting?: boolean;
	updateWhileAnimating?: boolean;
}

type Options = OptionsExt<ImageSource> & CustomOptions;

export default class GeoImage extends GeoImageExt {
	createRenderer(): any {
		const properties = this.getProperties();

		return new class extends CanvasImageLayerRenderer {
			prepareFrame(frameState: any) {

				const layerState = frameState.layerStatesArray[frameState.layerIndex];
				const pixelRatio = frameState.pixelRatio;
				const viewState = frameState.viewState;
				const viewResolution = viewState.resolution;

				const imageSource = this.getLayer().getSource();

				const hints = frameState.viewHints;

				let renderedExtent = frameState.extent;
				if (layerState.extent !== undefined) {
					renderedExtent = getIntersection(
						renderedExtent,
						fromUserExtent(layerState.extent, viewState.projection),
					);
				}

				const animating = hints[ViewHint.ANIMATING] && !properties.updateWhileAnimating;
				const interacting = hints[ViewHint.INTERACTING] && !properties.updateWhileInteracting;

				if (
					!animating
					&& !interacting
					&& !isEmpty(renderedExtent)
				) {
					if (imageSource) {
						const projection = viewState.projection;
						const image = imageSource.getImage(
							renderedExtent,
							viewResolution,
							pixelRatio,
							projection,
						);
						if (image) {
							if (this.loadImage(image)) {
								this.image = image;
							} else if (image.getState() === ImageState.EMPTY) {
								this.image = null;
							}
						}
					} else {
						this.image = null;
					}
				}

				return !!this.image;
			}
		}(this);
	}

	static createFromFeature(feature: Feature<Geometry>, url: string, scale = 1, options?: Partial<Options>) {
		const geometry = feature.getGeometry();

		if (!geometry) throw Error('Geometry not found from feature');

		const extent = geometry.getExtent();

		if (!extent) throw Error('Extent not found from feature');

		const layer = new GeoImage({
			source: new GeoImageSource({
				url,
				imageCenter: [0, 0],
				imageScale: 0,
				imageMask: undefined as any,
			}),
			...options,
		});

		// Récupérer l'objet image une fois chargé
		const img = (layer.getSource() as GeoImageSource).getGeoImage();

		// Attendre que l'image se charge
		img.addEventListener('load', () => layer.updateScaleFromExtent(extent, scale));

		return layer;
	}

	updateScaleFromExtent(extent: Extent, scale = 1) {
		const source = this.getSource() as GeoImageSource;
		updateScaleFromExtent(source, extent, scale);
	}
}