import AntdRadio from 'antd/lib/radio';
import React     from 'react';

export type RadioGroupProps = Omit<FieldProps, 'placeholder' | 'autofocus'> & {
	children: React.ReactNode;
	optionType?: 'default' | 'button';
}

export default function RadioGroup(props: RadioGroupProps) {
	return (
		<AntdRadio.Group
			disabled={props.disabled}
			id={props.id}
			onChange={props.onChange}
			size={props.size}
			style={props.style}
			value={props.value}

			optionType={props.optionType || 'button'}

			{...props}
		/>
	);
}
