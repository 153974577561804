import { Extent } from 'ol/extent';

export default (source: any, extent: Extent, scale = 1) => {
	const width = source.getGeoImage().width;
	const height = source.getGeoImage().height;

	const extentRatio = (extent[3]! - extent[1]!) / (extent[2]! - extent[0]!);
	const imageRatio = height / width;

	const extentWidth = extent[2]! - extent[0]!;
	const extentHeight = extent[3]! - extent[1]!;

	const imageScale = extentRatio > imageRatio ? extentWidth / width : extentHeight / height;

	source.setScale(imageScale * scale);

	source.setCenter([extent[0]! + (extent[2]! - extent[0]!) / 2, extent[1]! + (extent[3]! - extent[1]!) / 2]);
};
