import Switch from '@widesk/components/form/Switch';

export type InputBooleanProps = FieldProps & {
	initialValue?: boolean;
	value?: boolean;
}

export default function InputBoolean(props: InputBooleanProps) {
	return (
		<Switch
			checkedChildren="Oui"
			unCheckedChildren="Non"

			{...props}
		/>
	);
}
