import View                from '@widesk/components/View';
import Button              from '@widesk/components/Button';
import { SaveOutlined }    from '@ant-design/icons';
import { UndoOutlined }    from '@ant-design/icons';
import { RedoOutlined }    from '@ant-design/icons';
import { observer }        from 'mobx-react';
import React               from 'react';
import ButtonActions       from '@widesk/components/ButtonActions';
import useEditor           from '@/ol/editor/useEditor';
import useEditorAutoUpdate from '@/ol/editor/useEditorAutoUpdate';

function EditorHeader() {
	const { editor } = useEditor();

	const [submitting] = React.useState(false);

	useEditorAutoUpdate({
		valueToCheck: () => (editor.mapObjects.undoRedo as any).length(),
	});

	return (
		<View className="editor-header" row center bg="black" h={60} px={20}>
			<View flex row></View>

			<View flex row center gap="md">
				<Button
					disabled={!editor.mapObjects.undoRedo.hasUndo()}
					onClick={() => editor.mapObjects.undoRedo.undo()}
				>
					<UndoOutlined />
				</Button>

				<Button
					disabled={!editor.mapObjects.undoRedo.hasRedo()}
					onClick={() => editor.mapObjects.undoRedo.redo()}
				>
					<RedoOutlined />
				</Button>
			</View>

			<View flex row gap="sm" style={{ justifyContent: 'flex-end' }}>
				<ButtonActions forceButton actions={[
					{
						primary: true,
						loading: submitting,
						disabled: submitting,
						icon: <SaveOutlined />,
						label: 'Enregistrer',
						onClick: async () => {
							// setSubmitting(true);
							// await editor.saveOnDatabase();
							// setSubmitting(false);
						},
					},
					{
						confirm: `Êtes-vous sûr de vouloir quitter ?`,
						disabled: submitting,
						label: 'Quitter',
						// onClick: () => modalStore?.setIsOpen(false),
					},
				]} />
			</View>
		</View>
	);
}

const EditorHeaderObserver = observer(EditorHeader);

export default EditorHeaderObserver;