import AntdRadio      from 'antd/lib/radio';
import { RadioProps } from '@widesk/components/form/Radio';
import React          from 'react';

export type RadioButtonProps = RadioProps & {
	children: string | React.ReactNode;
};

export default function RadioButton(props: RadioButtonProps) {
	return (
		<AntdRadio.Button
			autoFocus={props.autoFocus}
			disabled={props.disabled}
			id={props.id}
			onChange={props.onChange}
			size={props.size}
			style={props.style}
			value={props.value}

			checked={props.checked}

			{...props}
		/>
	);
}
