import View         from '@widesk/components/View';
import useEditor    from '@/ol/editor/useEditor';
import Editor       from '@/ol/editor/Editor';
import Vector       from 'ol/layer/Vector';
import React        from 'react';
import { observer } from 'mobx-react-lite';
import Draw         from 'ol/interaction/Draw';

type Item = {
	title: React.ReactNode;
} & ({
	action: () => void;
	subItems?: undefined;
} | {
	action?: undefined;
	subItems: {
		title: React.ReactNode;
		action: () => void;
	}[];
});

const BTN_HEIGHT = 52;

let draw: Draw;

function EditionBar() {
	const { editor } = useEditor();

	const [cancelDraw, setCancelDraw] = React.useState(false);

	const items: Item[] = [
		{
			title: 'C',
			subItems: [
				{
					title: 'Polygon',
					action: () => startDraw(editor, 'Polygon'),
				},
				{
					title: 'Cercle',
					action: () => startDraw(editor, 'Circle'),
				},
			],
		},
		{
			title: 'R',
			subItems: [
				{
					title: 'Rectangle',
					action: () => startDraw(editor, 'Polygon'),
				},
			],
		},
	];

	const cancelDrawFunc = () => {
		editor.map.removeInteraction(draw);
		setCancelDraw(false);
	};

	return (
		<View bg="white" absT={50} m={10}>
			{cancelDraw ? (
				<View bg="green" onClick={cancelDrawFunc} h={BTN_HEIGHT} center px={10}>
					Annuler l'intéraction en cours
				</View>
			) : (
				<BtnManager items={items} onChange={() => {
					if (draw && editor.map.getInteractions().getArray().includes(draw)) {
						setCancelDraw(true);

						// Écouter les événements de dessin
						draw.on('drawend', cancelDrawFunc);
					}
				}} />
			)}
		</View>
	);
}

const EditionBarObserver = observer(EditionBar);

export default EditionBarObserver;

const BtnManager = (props: { items: Item[]; onChange: () => void; }) => {
	const [mIndex, setMIndex] = React.useState<number>();

	const items = props.items.map((item, index) => {
		return {
			...item,
			action: () => {
				setMIndex(index !== mIndex ? index : undefined);
				item.action?.();
				props.onChange();
			},
			subItems: !item.subItems ? undefined : item.subItems.map(i => {
				return {
					...i,
					action: () => {
						setMIndex(undefined);
						i.action();
						props.onChange();
					},
				};
			}),
		};
	}) as Item[];

	return (
		<>
			{items.map((item, idx) => {
				return <Btn key={idx} active={mIndex === idx} {...item} />;
			})}
		</>
	);
};

const Btn = (props: Item & { active: boolean; }) => {
	const SUB_ITEM_WIDTH = 220;

	return (
		<View
			w={BTN_HEIGHT}
			h={BTN_HEIGHT}
			center
			onClick={props.action}
			bg={props.active ? 'red' : undefined}
			rel
		>
			{props.title}

			{props.active && !!props.subItems && (
				<View bg="blue" w={SUB_ITEM_WIDTH} absT={0} absR={-SUB_ITEM_WIDTH}>
					{props.subItems.map((subItem, idx) => {
						return (
							<View key={idx} onClick={subItem.action} h={BTN_HEIGHT} bg="green" centerV px={10}>
								{subItem.title}
							</View>
						);
					})}
				</View>
			)}
		</View>
	);
};

const startDraw = (editor: Editor, type: 'Polygon' | 'Circle') => {
	// Accéder à toutes les couches de la carte
	const layers = editor.map.getLayers().getArray();

	// Parcourir les couches pour trouver la couche vectorielle
	const layer = layers.find(layer => {
		return (
			layer instanceof Vector
			&& layer.get('name') === 'vector_layer_places'
		);
	}) as Vector<any> | undefined;

	if (!layer) return;

	editor.map.removeInteraction(draw);

	draw = new Draw({ source: layer.getSource(), type });

	// Ajouter l'interaction de dessin à la carte
	editor.map.addInteraction(draw);
};