import View  from '@widesk/components/View';
import React from 'react';

const SIDEBAR_WIDTH = 356;

export default function EditorSidebar({ children, sidebarContent, opened }: {
	children: React.ReactNode;
	sidebarContent: () => React.ReactNode;
	opened?: boolean;
}) {
	const [hidden, setHidden] = React.useState(!opened);

	React.useEffect(() => {
		if (opened) setHidden(false);
		else setTimeout(() => setHidden(true), 350);
	}, [opened]);

	return (
		<View row flex style={{ overflow: 'hidden' }} rel>
			{children}

			<View
				absR={opened ? 0 : -SIDEBAR_WIDTH}
				bg="white"
				className="editor-sidebar"
				heightF
				width={SIDEBAR_WIDTH}
			>
				{!hidden && sidebarContent()}
			</View>
		</View>
	);
}