import AntdSwitch from 'antd/lib/switch';
import React      from 'react';

export type SwitchProps = FieldProps & {
	initialValue?: boolean;
	value?: boolean;
	checkedChildren?: React.ReactNode;
	unCheckedChildren?: React.ReactNode;
}

export default function Switch(props: SwitchProps) {

	// Permet de définir la value à "false" lorsqu'elle est "undefined"
	React.useEffect(() => {
		if (
			typeof props.value === 'undefined'
			&& typeof props.initialValue === 'undefined'
			&& props.onChange
		) {
			props.onChange(false);
		}
	}, [props.value]);

	return (
		<AntdSwitch
			defaultChecked={props.initialValue}
			autoFocus={props.autoFocus}
			id={props.id ? `switch-${props.id}` : undefined}
			disabled={props.disabled}
			onChange={props.onChange}
			size={props.size === 'small' ? 'small' : undefined}
			checked={props.value}
			checkedChildren={props.checkedChildren}
			unCheckedChildren={props.unCheckedChildren}
		/>
	);
}
