import 'ol/ol.css';

import React                       from 'react';
import View                        from 'ol/View';
import Map                         from 'ol/Map';
import TileLayer                   from 'ol/layer/Tile';
import OSM                         from 'ol/source/OSM';
import geoJson                     from './data/geojson.json';
import VectorSource                from 'ol/source/Vector';
import VectorLayer                 from 'ol/layer/Vector';
import { Select }                  from 'ol/interaction';
import { Modify }                  from 'ol/interaction';
import GeoJSON                     from 'ol/format/GeoJSON.js';
import { click }                   from 'ol/events/condition';
import GeoImage                    from '@/ol/GeoImage';
import getFeaturePlaceStyles       from '@/ol/tools/getFeaturePlaceStyles';
import _                           from 'lodash';
import checkFeatureForImageDisplay from '@/ol/tools/checkFeatureForImageDisplay';

const extent = [279035.0685416447, 6268521.5136298, 282300.21862668183, 6271536.151549665]; // Parc des expo
const iconEnabled = new URLSearchParams(window.location.search).has('icon');
const png = new URLSearchParams(window.location.search).has('png');

export default function App2({ edition, places, images }: {
	edition?: boolean;
	places?: boolean;
	images?: boolean;
}) {
	React.useEffect(() => {
		console.log(geoJson);

		const map = new Map({
			target: 'map',
			layers: [
				new TileLayer({ source: new OSM({ attributions: '' }) }),
			],
			view: new View({
				extent,
				center: [0, 0],
				zoom: 16,
				showFullExtent: true,
			}),
		});

		if (images) importImages(map);
		if (places) importPlaces(map, edition);
	}, []);

	return (
		<div style={{
			position: 'relative',
			display: 'flex',
			flexDirection: 'row',
			width: '100vw',
			height: '100vh',
		}}>
			<div id="map" className="map" style={{ flex: 1 }}></div>
		</div>
	);
}

const importImages = (map: Map) => {
	const geoJsonImages = {
		...geoJson.zones,
		features: geoJson.zones.features.filter(feature => (
			feature.properties.type !== 'Place'
			&& feature.properties.resourceUrl
		)),
	};

	const features = new GeoJSON().readFeatures(geoJsonImages, {
		dataProjection: 'EPSG:4326',
		featureProjection: 'EPSG:3857',
	});

	features.forEach((feature, idx) => {
		const url = (png && feature.getProperties().resourceUrl === '/aaa4.svg') ?
			'/aaa4.png' : feature.getProperties().resourceUrl;
		const imageLayer = GeoImage.createFromFeature(feature, url, 1, {
			updateWhileInteracting: idx === 0,
			updateWhileAnimating: idx === 0,
		});
		map.addLayer(imageLayer);
	});
};

const importPlaces = (map: Map, edition?: boolean) => {
	const geoJsonPlaces = {
		...geoJson.zones,
		features: geoJson.zones.features.filter(feature => (
			feature.properties.type === 'Place'
		)),
	};

	const features = new GeoJSON().readFeatures(geoJsonPlaces, {
		dataProjection: 'EPSG:4326',
		featureProjection: 'EPSG:3857',
	});

	// Source vectorielle contenant les données GeoJSON
	const vectorSource = new VectorSource({ features });

	// Couche vectorielle pour afficher les données GeoJSON
	const vectorLayer = new VectorLayer({
		source: vectorSource,
		style: getFeaturePlaceStyles,
	});

	// Ajout de la couche vectorielle à la carte
	map.addLayer(vectorLayer);

	if (edition) {
		// Interaction pour sélectionner des features indépendamment
		const select = new Select({
			condition: click, // Permet de sélectionner des features en cliquant
			layers: [vectorLayer], // Limite la sélection à la couche vectorielle contenant les GeoJSON
		});
		map.addInteraction(select);

		// Interaction pour modifier les features sélectionnées
		const modify = new Modify({
			features: select.getFeatures(), // Modifie seulement les features sélectionnées
		});

		map.addInteraction(modify);

		// Fonction pour surveiller les changements et appliquer des actions après modification
		modify.on('modifyend', function (event) {
			console.log('Modification terminée : ', event.features.getArray()); // Affiche les features modifiées
		});
	}

	// // Utilisation de Lodash pour créer une fonction debounced
	// const debouncedZoomEnd = _.debounce(() => vectorLayer.setVisible(true), 500); // Ajustez le délai selon vos besoins
	//
	// // Écoute de l'événement de changement de zoom
	// map.getView().on('change:resolution', (e) => {
	// 	// Seulement lorsqu'on de-zoom
	// 	if (e.target.getResolution() > e.oldValue) {
	// 		vectorLayer.setVisible(false);
	// 	}
	//
	// 	debouncedZoomEnd();
	// });

	const initializedPlaceImages: Record<number, boolean> = {};
	const placeWithImageFeatures = features.filter(feature => {
		return !iconEnabled && feature.getProperties().entity.image;
	});

	const initializeImages = (resolution: number) => {
		placeWithImageFeatures.forEach(feature => {
			const properties = feature.getProperties();
			const url = properties.entity.image;
			const id = properties.entity.id;

			if (initializedPlaceImages[id]) return;

			if (!checkFeatureForImageDisplay(feature, resolution)) return;

			initializedPlaceImages[id] = true;

			const imageLayer = GeoImage.createFromFeature(feature, url, 0.4);

			map.addLayer(imageLayer);
		});
	};

	const debouncedInitializeImages = _.debounce(initializeImages, 500); // Ajustez le délai selon vos besoins

	map.getView().on('change:resolution', (e) => debouncedInitializeImages(e.target.getResolution()));

	initializeImages(map.getView().getResolution() || 0);
};
