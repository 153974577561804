import View                      from '@widesk/components/View';
import Image                     from '@widesk/components/Image';
import { FormatPainterOutlined } from '@ant-design/icons';
import { InfoCircleOutlined }    from '@ant-design/icons';
import useTheme                  from '@widesk/hooks/useTheme';
import React                     from 'react';
import InputBoolean              from '@widesk/components/form/InputBoolean';
import { ViewProps }             from '@widesk/components/View/viewTypes';
import useEditor                 from '@/ol/editor/useEditor';

const ICON_SIZE = 26;
const ICON_COLOR = 'rgba(0,0,0,0.4)';

type SidebarProps = {
	entity: FeatureEntity;
}

export default function PlaceSidebar(props: SidebarProps) {
	const theme = useTheme();

	const [menu, setMenu] = React.useState<0 | 1>(0);

	return (
		<View row heightF>
			<View flex>
				{(() => {
					switch (menu) {
						case 0:
							return <Menu1 {...props} />;

						case 1:
							// TODO retirer "as any" pour les props
							return <Menu2 {...props as any} />;
					}
				})()}
			</View>

			<View w={70}>
				<View onClick={() => setMenu(0)} h={70} center>
					<InfoCircleOutlined style={{
						fontSize: ICON_SIZE,
						color: menu === 0 ? theme.colorPrimary : ICON_COLOR,
					}} />
				</View>

				<View onClick={() => setMenu(1)} h={70} center>
					<FormatPainterOutlined style={{
						fontSize: ICON_SIZE,
						color: menu === 1 ? theme.colorPrimary : ICON_COLOR,
					}} />
				</View>
			</View>
		</View>
	);
}

const Menu1 = ({ entity }: SidebarProps) => {
	return (
		<View>
			<View className="editor-sidebar-title">Informations</View>

			<View row gap="xs">
				<Image
					placeholder
					size="extralarge"
					bordered
					src={entity.image}
				/>

				<View color="rgba(0,0,0,0.6)" gap="xxs">
					<View>{entity.label}</View>
					<View>{entity.reference}</View>
					<View>{entity.location}</View>
				</View>
			</View>
		</View>
	);
};

const Menu2 = () => {
	const { editor } = useEditor();

	const feature = editor.selectedFeature;

	if (!feature) return null;

	const properties = feature.getProperties() as FeatureProperties;

	return (
		<View>
			MENU 2

			<Field label="Afficher l'image" row>
				<InputBoolean
					initialValue={properties.imageEnabled}
					// value={properties.imageEnabled}
					// onChange={v => zone?.setProperty('imageEnabled', !!v)}
					onChange={v => {
						editor.setFeatureProperty(feature, 'imageEnabled', !!v);
					}}
				/>
			</Field>
		</View>
	);
};

const Label = (props: { children: React.ReactNode } & ViewProps) => <View className="editor-sidebar__content__label" {...props} />;

const Field = (props: {
	label: string;
	children: React.ReactNode;
} & ViewProps) => (
	<View gap="xxs" spread {...props}>
		<Label>{props.label}</Label>

		<View gap="xs">
			{props.children}
		</View>
	</View>
);