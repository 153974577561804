import useForceUpdate from '@widesk/hooks/useForceUpdate';
import React          from 'react';

export default function useEditorAutoUpdate({
	ms = 200,
	valueToCheck, // Quand la valeur retournée par la méthode change, on forceUpdate le composant
}: {
	ms?: number;
	valueToCheck?: () => any;
}) {
	const forceUpdate = useForceUpdate();
	const oldValueRef = React.useRef();

	React.useEffect(() => {
		const interval = setInterval(() => {
			if (valueToCheck) {
				const value = valueToCheck();
				if (value !== oldValueRef.current) {
					forceUpdate();
					oldValueRef.current = value;
				}
			} else {
				forceUpdate();
			}
		}, ms);

		return () => {
			clearInterval(interval);
		};
	}, []);
}
