import UserModel           from '@models/account/UserModel';
import doc                 from '@widesk/decorators/doc';
import model               from '@widesk/decorators/model';
import AccountServiceModel from '@/models/AccountServiceModel';

@model.urnResource('impersonate_user')
@doc.path('/impersonate_users/{?id}')
export default class ImpersonateUserModel extends AccountServiceModel {
	private declare _tsFilters: unknown;

	private declare _tsSorts: unknown;

	@doc.string declare token: string;
	@doc.modelUrn(UserModel) declare user: UserModel;
}