import React             from 'react';
import { createContext } from 'react';
import View              from '@widesk/components/View';
import Editor            from '@/ol/editor/Editor';
import Spin              from '@widesk/components/Spin';

export const EditorContext = createContext<{
	editor: Editor;
}>({
	editor: new Editor(),
});

type EditorProviderProps = {
	children: React.ReactNode;
}

export default function EditorProvider({ children }: EditorProviderProps) {
	const editor = React.useMemo(() => new Editor(), []);

	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		(async () => {
			await editor.initialize();
			setLoading(false);
		})();
	}, []);

	return (
		<EditorContext.Provider value={{ editor }}>
			<View heightF rel>
				{loading && <EditorSpinner />}
				{children}
			</View>
		</EditorContext.Provider>
	);
}

const EditorSpinner = () => {
	return (
		<Spin
			absT={0}
			widthF
			heightF
			zIndex={999}
			center
			size="large"
			tip="Chargement de la carte"
		/>
	);
};