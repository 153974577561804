import View        from '@widesk/components/View';
import RadioButton from '@widesk/components/form/RadioButton';
import RadioGroup  from '@widesk/components/form/RadioGroup';
import React       from 'react';

export default function ChooseTypeSidebar() {
	const [type, setType] = React.useState<'place' | 'bg'>('place');

	return (
		<View>
			<View className="editor-sidebar-title">Informations</View>

			<RadioGroup optionType="default" value={type} onChange={(e: any) => setType(e.target.value)}>
				<RadioButton value="place">Une Place</RadioButton>
				<RadioButton value="bg">Une image de fond</RadioButton>
			</RadioGroup>

			{(() => {
				switch (type) {
					case 'place':
						return 'TODO ASSIGN PLACE'; // TODO

					case 'bg':
						return 'TODO ADD IMAGE BUTTON'; // TODO
				}
			})()}
		</View>
	);
}