import Map                         from 'ol/Map';
import TileLayer                   from 'ol/layer/Tile';
import OSM                         from 'ol/source/OSM';
import View                        from 'ol/View';
import sleep                       from '@widesk/tools/sleep';
import geoJsonData                 from '@/ol/data/geojson.json';
import GeoImage                    from '@/ol/GeoImage';
import { Geometry }                from 'geojson';
import { FeatureCollection }       from 'geojson';
import VectorSource                from 'ol/source/Vector';
import VectorLayer                 from 'ol/layer/Vector';
import { Select }                  from 'ol/interaction';
import { Modify }                  from 'ol/interaction';
import UndoRedo                    from 'ol-ext/interaction/UndoRedo';
import GeoJSON                     from 'ol/format/GeoJSON.js';
import getFeaturePlaceStyles       from '@/ol/tools/getFeaturePlaceStyles';
import checkFeatureForImageDisplay from '@/ol/tools/checkFeatureForImageDisplay';
import { click }                   from 'ol/events/condition';
import _                           from 'lodash';
import Feature                     from 'ol/Feature';
import { FeatureLike }             from 'ol/Feature';
import { getCenter }               from 'ol/extent';
import { action }                  from 'mobx';
import { makeObservable }          from 'mobx';
import { observable }              from 'mobx';

const IMAGE_ROTATION = new URLSearchParams(window.location.search).has('icon');
const edition = true;

const extent = [279035.0685416447, 6268521.5136298, 282300.21862668183, 6271536.151549665]; // Parc des expo

type GEO_JSON_DATA = {
	zones: FeatureCollection<Geometry, FeatureProperties>
};

export default class Editor {
	private _map?: Map;

	@observable
	private _selectedFeature?: FeatureLike;

	public readonly mapObjects: {
		vectorLayer: VectorLayer;
		undoRedo: UndoRedo;
	};

	public initialized = false;

	constructor() {
		makeObservable(this);

		// Source vectorielle contenant les données GeoJSON
		const vectorSource = new VectorSource();

		// Couche vectorielle pour afficher les données GeoJSON
		const vectorLayer = new VectorLayer({
			source: vectorSource,
			style: getFeaturePlaceStyles,
		});

		vectorLayer.set('name', 'vector_layer_places');

		const undoRedo = new UndoRedo();

		undoRedo.define(
			'featureProperty',
			(s: any) => s.feature.set(s.name, s.oldValue),
			(s: any) => s.feature.set(s.name, s.value),
		);

		this.mapObjects = { vectorLayer, undoRedo };
	}

	async initialize() {
		await sleep(0);
		this.initialized = true;
		this._map = new Map({ target: 'map' });

		// Récupération des données geoJson
		const geoJson = geoJsonData as GEO_JSON_DATA; // TODO récupérer les données en ligne

		if (IMAGE_ROTATION) { // TODO A Supprimer
			geoJson.zones.features.forEach(f => {
				if (f.properties.entity?.image) f.properties.entity.imageRotation = true;
			});
		}

		this.map.addInteraction(this.mapObjects.undoRedo);

		this._importGeoJson(geoJson);
	}

	public get map() {
		return this._map!;
	}

	public get selectedFeature() {
		return this._selectedFeature as any; // TODO TYPER
	}

	public centerFeature(feature: FeatureLike | Feature) {
		const view = this.map.getView();
		const extent = feature.getGeometry()?.getExtent();

		if (extent) {
			// Animation de la vue de la carte vers les coordonnées de la feature
			view.animate({
				center: getCenter(extent),
				duration: 200,
				zoom: view.getZoom(),
			});
		}
	}

	public setFeatureProperty(
		feature: Feature,
		name: keyof FeatureProperties,
		value: any, // todo
	) {
		const oldValue = feature.get(name);  // Récupérer l'ancienne valeur

		this.mapObjects.undoRedo.push('featureProperty', { oldValue, value, feature, name }, name);

		feature.set(name, value);

		feature.dispatchEvent('change');
	}

	private _importGeoJson(geoJson: GEO_JSON_DATA) {
		this.map.setView(
			new View({
				extent,
				center: [0, 0],
				zoom: 16,
				showFullExtent: true,
			}),
		);

		this.map.addLayer(
			new TileLayer({ source: new OSM({ attributions: '' }) }),
		);

		this._importBackgrounds(geoJson);
		this._importPlaces(geoJson);
	}

	private _importBackgrounds(geoJson: GEO_JSON_DATA) {
		const geoJsonImages = {
			...geoJson.zones,
			features: geoJson.zones.features.filter(feature => (
				feature.properties.resourceUrl
			)),
		};

		const features = new GeoJSON().readFeatures(geoJsonImages, {
			dataProjection: 'EPSG:4326',
			featureProjection: 'EPSG:3857',
		});

		features.forEach((feature, idx) => {
			const url = feature.getProperties().resourceUrl;
			const imageLayer = GeoImage.createFromFeature(feature, url, 1, {
				updateWhileInteracting: idx === 0,
				updateWhileAnimating: idx === 0,
			});
			this.map.addLayer(imageLayer);
		});
	}

	private _importPlaces(geoJson: GEO_JSON_DATA) {
		const geoJsonPlaces = {
			...geoJson.zones,
			features: geoJson.zones.features.filter(feature => (
				feature.properties.type === 'Place'
			)),
		};

		const features = new GeoJSON().readFeatures(geoJsonPlaces, {
			dataProjection: 'EPSG:4326',
			featureProjection: 'EPSG:3857',
		});

		const vectorLayer = this.mapObjects.vectorLayer;
		const vectorSource = vectorLayer.getSource();

		vectorSource?.addFeatures(features);

		// Ajout de la couche vectorielle à la carte
		this.map.addLayer(vectorLayer);

		if (edition) {
			// Interaction pour sélectionner des features indépendamment
			const select = new Select({
				condition: click, // Permet de sélectionner des features en cliquant
				layers: [vectorLayer], // Limite la sélection à la couche vectorielle contenant les GeoJSON
				style: getFeaturePlaceStyles,
			});
			this.map.addInteraction(select);

			// Interaction pour modifier les features sélectionnées
			const modify = new Modify({
				features: select.getFeatures(), // Modifie seulement les features sélectionnées
			});

			this.map.addInteraction(modify);

			select.on('select', e => {
				e.selected.forEach(feature => this._setFeatureSelected(feature, true));
				e.deselected.forEach(feature => this._setFeatureSelected(feature, false));
			});
		}

		const initializedPlaceImages: Record<number, boolean> = {};
		const placeWithImageFeatures = features.filter(feature => {
			const properties = feature.getProperties();
			return !properties.entity?.imageRotation && properties.entity?.image;
		});

		const initializeImages = (resolution: number) => {
			placeWithImageFeatures.forEach(feature => {
				const properties = feature.getProperties();
				const url = properties.entity.image;
				const id = properties.entity.id;

				if (initializedPlaceImages[id]) return;

				if (!checkFeatureForImageDisplay(feature, resolution)) return;

				initializedPlaceImages[id] = true;

				const imageLayer = GeoImage.createFromFeature(feature, url, 0.4);

				this.map.addLayer(imageLayer);
			});
		};

		const debouncedInitializeImages = _.debounce(initializeImages, 500); // Ajustez le délai selon vos besoins

		this.map.getView().on('change:resolution', (e) => debouncedInitializeImages(e.target.getResolution()));

		initializeImages(this.map.getView().getResolution() || 0);
	}

	@action
	private _setFeatureSelected(feature: Feature, selected = true) {
		feature.set('selected', selected);

		this._selectedFeature = selected ? feature : undefined;

		if (selected) this.centerFeature(feature);
	}
}