import AccountModel        from '@/models/services/account/AccountModel';
import ProjectModel        from '@/models/services/map/ProjectModel';
import UserModel           from '@models/account/UserModel';
import PagedCollection     from '@widesk/models/PagedCollection';
import { observable }      from 'mobx';
import { action }          from 'mobx';
import { makeObservable }  from 'mobx';

class AppStore {
	public readonly user = new UserModel();

	public projectCollection = new PagedCollection(ProjectModel);

	@observable
	private _activeProject: ProjectModel | null = null;

	@observable
	private _account: AccountModel | null = null;

	constructor() {
		makeObservable(this);
		const localItem = localStorage.getItem('accountId');
		if (localItem) {
			this.fetchAccount(localItem);
		}
	}

	public async load() {
		this.projectCollection.setItemsPerPage(5);
		this.projectCollection.setSort('updatedAt', 'desc');
		this.projectCollection.setRequiredFilter('owner.accountUrn', this.account?.urn);
		this.projectCollection.list();
	}

	@action
	public async fetchProject(id: id) {
		this._activeProject = new ProjectModel({ id: id });
		await this._activeProject.fetch();
	}

	@action
	public resetActiveProject() {
		this._activeProject = null;
	}

	public get activeProject() {
		return this._activeProject;
	}

	@action
	public async fetchAccount(id: id) {
		if (this._account?.id !== id) {
			localStorage.setItem('accountId', id);
			this._account = new AccountModel({ id: id });
			await this._account.fetch();
			this.load();
		}
	}

	@action
	public resetAccount() {
		this._account = null;
	}

	public get account() {
		return this._account;
	}

	public get accountUrn() {
		return this.account?.urn || (this.account?.id && AccountModel.makeUrn(this.account.id)) || '';
	}

	public clear() {
		this.user.clear();
		this.resetAccount();
		this.resetActiveProject();
		localStorage.removeItem('accountId');
	}
}

export default new AppStore();
