import Form                               from '@widesk/components/form/Form';
import Input                              from '@widesk/components/form/Input';
import Box                                from '@widesk/components/Box';
import View                               from '@widesk/components/View';
import Button                             from '@widesk/components/Button';
import useTheme                           from '@widesk/hooks/useTheme';
import InputPassword                      from '@widesk/components/form/InputPassword';
import useDidMount                        from '@widesk/hooks/useDidMount';
import Logo                               from '@/components/Logo';
import Title                              from '@widesk/components/Title';
import { useParams }                      from 'react-router-dom';
import Alert                              from '@widesk/components/Alert';
import LoginCheckModel                    from '@/models/services/admin/LoginCheckModel';
import ImpersonateUserModel               from '@/models/services/account/ImpersonateUserModel';
import { message }                        from '@widesk/hooks/useMessage';
import authStore                          from '@widesk/stores/authStore';
import { getIdFromUrn, isUrn }            from '@widesk/tools/identifier';
import appStore                           from '@/stores/appStore';
import { navigate }                       from '@widesk/hooks/useNavigate';

export default function ImpersonatePage() {
	const theme = useTheme();
	const { urn: userUrn } = useParams();

	useDidMount(() => {
		document.title = 'Authentification';
	});

	const impersonateLogin = async (username: string, password: string) => {
		try {
			
			const loginCheck = new LoginCheckModel();
			await loginCheck.patch({ password, username });
			
			navigate('/');

			authStore.setToken(loginCheck.token);
			
			const impersonateUserModel = new ImpersonateUserModel();
			
			impersonateUserModel.set({
				userUrn: userUrn,
			});
			
			await impersonateUserModel.save();
			
			authStore.setToken(impersonateUserModel.token);
			
			const userId = getIdFromUrn(authStore.tokenData['impersonate.urn']);
			
			if (userId) {
				await appStore.user.setId(userId).fetch();
			}
			
		} catch (error) {
			message.error('Erreur lors de la connexion');
		}
	};

	const isUrnOk = userUrn && isUrn(userUrn); 

	return (
		<View heightF center gap={theme.marginLG}>
			<Box style={{ width: 460, padding: theme.marginLG }}>
				<View gap={theme.marginXXL}>
					<View row={false} center>
						<Logo />
						<Title marginT="xl" marginB="xl" level={2}>Connectez-vous</Title>
						{!isUrnOk ? (
							<Alert type="error" message="Utilisateur non trouvé." />							

						) : (
							<Alert type="info" message="Entrez vos identifiants d'administrateur pour vous connecter à la place d'un autre utilisateur." />							
						)}
					</View>
					{isUrnOk && (
						<Form
							fields={{
								username: {
									span: 24,
									element: <Input id="username" placeholder="Votre identifiant" size="large" />,
									required: true,
								},
								password: {
									span: 24,
									element: <InputPassword id="password" placeholder="Votre mot de passe" size="large" />,
									required: true,
								},
							}}
							onSubmit={async ({ username, password }) => impersonateLogin(username as string, password as string)}
						>
							<Button size="large" block type="primary" htmlType="submit">
								Connectez-vous
							</Button>
						</Form>
					)}
				</View>
			</Box>
		</View>
	);
}
