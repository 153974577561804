import { createBrowserRouter }                   from 'react-router-dom';
import { RouterProvider as ReactRouterProvider } from 'react-router-dom';
import AuthLayout                                from '@widesk/layouts/AuthLayout';
import { useAuth }                               from '@widesk/hooks/useAuth';
import LoginPage                                 from '@widesk/pages/LoginPage';
import Layout                                    from '@widesk/layouts/Layout';
import LayoutSider                               from '@/components/LayoutSider';
import LayoutHeader                              from '@/components/LayoutHeader';
import BreadcrumbBar                             from '@widesk/components/BreadcrumbBar';
import ErrorPage                                 from '@widesk/pages/ErrorPage';
import View                                      from '@widesk/components/View';
import Spin                                      from '@widesk/components/Spin';
import Button                                    from '@widesk/components/Button';
import Title                                     from '@widesk/components/Title';
import useTheme                                  from '@widesk/hooks/useTheme';
import Link                                      from '@widesk/components/Link';
import Logo                                      from './components/Logo';
import AccountWall                               from './components/AccountWall';
import useAccount                                from './hooks/useAccount';
import ImpersonatePage                           from './pages/Impersonate';
import ImpersonateLogged                         from './pages/Impersonate/ImpersonateLogged';

// On charge tous les fichiers "Route" du projet
const publicModules = import.meta.glob([
	'./routes/public/*Route.ts',
	'./routes/public/*Route.tsx',
], { eager: true });

const privateModules = import.meta.glob([
	'./routes/private/*Route.ts',
	'./routes/private/*Route.tsx',
	'./routes/private/services/*/*Route.ts',
	'./routes/private/services/*/*Route.tsx',
], { eager: true });

const publicRouter = createBrowserRouter([
	{
		element: <Layout />,
		children: [
			{
				path: '/impersonate/:urn',
				element: (
					<ImpersonatePage />
				),
			},
			...Object.values(publicModules).flatMap((module: any) => module.default),
			{
				path: '*',
				element: (
					<LoginPage
						footer={<View align="center"><Link to="/reset_password">Mot de passe oublié</Link></View>}
						header={
							<View row={false} center>
								<Logo />
								<Title marginT="xl" level={2}>Connectez-vous</Title>
							</View>
						}
					/>
				),
			},
		],
	},
]);

const privateRouter = createBrowserRouter([
	{
		element: (
			<AuthLayout
				renderHeader={() => <LayoutHeader />}
				renderSider={() => <LayoutSider />}
				renderTopPage={() => <BreadcrumbBar />}
			/>
		),
		children: [
			{
				path: '/impersonate/:urn',
				element: (
					<ImpersonateLogged />
				),
			},
			...Object.values(privateModules).flatMap((module: any) => module.default),
		],
		errorElement: <ErrorPage />,
	},
]);

const noAccountPrivateRouter = createBrowserRouter([
	{
		element: (
			<AuthLayout
				renderHeader={() => <LayoutHeader />}
				renderSider={() => <LayoutSider />}
				renderTopPage={() => <BreadcrumbBar />}
			/>
		),
		children: [
			{
				path: '/impersonate/:urn',
				element: (
					<ImpersonateLogged />
				),
			},
			{
				path: '*',
				element: (
					<AccountWall />
				),
			},
		],
		errorElement: <ErrorPage />,
	},
]);

export default function RouterProvider() {
	const { token, isLogging } = useAuth();
	const { account } = useAccount();
	
	return (
		<>
			{token ? (
				isLogging ? 
					<LoadingPage /> 
					: !account ? 
						<ReactRouterProvider router={noAccountPrivateRouter} /> 
						: <ReactRouterProvider router={privateRouter} />
			) : (
				<ReactRouterProvider router={publicRouter} />
			)}
		</>
	);
}

const LoadingPage = () => {
	const { logout } = useAuth();
	const theme = useTheme();

	return (
		<View height="100vh" center gap={20} bg={theme.colorBgContainer} color={theme.colorTextBase}>
			<Spin size="large" tip="Chargement de l'application" />

			<Button type="link" onClick={() => logout()}>Déconnexion</Button>
		</View>
	);
};
